import React from "react";
import classes from "./Invoice.module.css";
import Utils from "../../../../Utils";

function InvoiceItems({ items = [] }) {
  return (
    <div className={classes.invoiceItems}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tableHeadRow}>
            <th className={classes.tableHeadCell}>Item</th>
            <th className={`${classes.tableHeadCell} ${classes.textCenter}`}>
              Price
            </th>
            <th className={`${classes.tableHeadCell} ${classes.textCenter}`}>
              Qty
            </th>
            <th className={`${classes.tableHeadCell} ${classes.textRight}`}>
              Total Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index} className={classes.tableBodyRow}>
              <td className={classes.tableBodyCell}>
                <div className={classes.itemDetails}>
                  <p className={classes.itemName}>{item.title}</p>
                  <p className={classes.itemDescription}>{item.description}</p>
                </div>
              </td>
              <td className={`${classes.tableBodyCell} ${classes.textCenter}`}>
                {Utils.getRupeeSymbol} {item.price.toFixed(2)}
              </td>
              <td className={`${classes.tableBodyCell} ${classes.textCenter}`}>
                x {item.quantity}
              </td>
              <td className={`${classes.tableBodyCell} ${classes.textRight}`}>
                <div className={classes.totalAmountWrapper}>
                  <p>
                    {Utils.getRupeeSymbol}{" "}
                    {(item.price.toFixed(2) * item.quantity).toFixed(2)}
                  </p>
                  <p className={classes.discountCell}>
                    Author Disc.: -{item.authorDiscount.toFixed(2)}
                  </p>
                  <p className={classes.discountCell}>
                    Quantity Disc.: -{item.quantityDiscount.toFixed(2)}
                  </p>
                  <p className={classes.finalItemAmount}>
                  {Utils.getRupeeSymbol}{" "}
                  {item.totalAmount.toFixed(2)}
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default InvoiceItems;
