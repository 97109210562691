import React from "react";
import classes from "./Invoice.module.css";
import Utils from "../../../../Utils";

function InvoiceHeader({ data }) {
  return <div className={classes.invoiceHeader}>
  <div className={classes.section}>
    <div className={classes.item}>
      <span>Invoice</span>
      <span className={classes.primary}>#{data?.orderNo}</span>
    </div>
    <div>
      <h3>Delivery Date</h3>
      <p className={classes.muted}>{Utils.getDateStringWithoutTime(data?.deliveryDate)}</p>
    </div>
  </div>
  <div className={classes.section}>
    <h3>Shipping Details</h3>
    <p className={classes.muted}>{data?.customerName}</p>
    <p className={classes.muted}>{`${data?.address}, ${data.addressLine2 && data.addressLine2 + ","}, ${data?.landmark}`}</p>
    <p className={classes.muted}>{`${data?.city}, ${data?.state}, ${data?.pincode}`}</p>
  </div>
</div>
}

export default InvoiceHeader;
