import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router';
import Utils from "../../Utils";
import classes from "./SignUp.module.css";
import { createUser, verifyUser, resendCode, logout } from '../../modules/Users/UsersActions';
import signUpbackgroundImage from '../../assets/images/signup-background.svg';
import signUpBackgroundStars from '../../assets/images/signup-background-stars.svg';
import logoImage from "../../assets/bookalooza-logo.png";
import logoWithTagline from "../../assets/images/tagline.png";
import OTPForm from "./OTPForm";
import InfoForm from "./InfoForm";
import Consts from "../../Consts";
import { useDispatch } from "react-redux";
import ModalDialog from "../Dialog/Dialog";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

function otpVerification(data, history, postSignUpProcess) {
    return verifyUser(data).then((response) => {
        if (response && response?.data?.success) {
            postSignUpProcess();
        } else {
            throw {
                msg: 'Entered OTP is wrong'
            };
        }
    });
}

const infoConfig = {
    heading: "Sign Up",
    subHeading: "Hey! Enter your details to create your account.",
    fullname: true,
    phoneNumber: true,
    schoolName: true
}


export function SignupForm({ postSignUpProcess, goToLogin }) {
    const history = useHistory();
    const location = useLocation();
    const linkId = Utils.getValueFromSearchParam(location.search, "link");
    const [show, setShow] = useState(linkId ? false : true);
    const [userInfo, setUserInfo] = useState(null);
    const [isEmailValid, setEmailValid] = useState(true);
    const [otpError, setOTPError] = useState('');

    const handleClose = () => setShow(false);

    return (
        <div className={classes.leftMainBox}>
            {
                show && <ModalDialog
                    title={<b>Have You Registered Through School?</b>}
                    onClose={handleClose}
                    content={<div className={classes.disclaimer}>
                        <p>If yes, please log in using your <b>User ID and password</b> to create a book of up to <b>32 pages</b>. The amount you paid at school will be adjusted when you place your book order. If you’ve already registered but <b>haven’t received</b> your User ID and password, please get in touch with your <b>school coordinator.</b></p>
                        <p><b>Note:</b> Please do not create a new account if you have already registered through the school. The charges will be different, and we cannot give you a special discount.</p>
                        <h2 className={classes.modelHeading}><b>क्या आपने स्कूल के ज़रिए रजिस्ट्रेशन किया है?</b></h2>
                        <p>अगर हाँ, तो अपना <b>यूज़र आईडी और पासवर्ड</b> डालकर लॉगिन करें और <b>32 पेज तक</b> की किताब बनाएं। स्कूल में जो पैसे जमा किए थे, वो किताब ऑर्डर करते समय एडजस्ट हो जाएंगे।</p>
                        <p>अगर आपने रजिस्ट्रेशन कर लिया है लेकिन यूज़र आईडी और पासवर्ड नहीं मिला, तो अपने <b>स्कूल के कोऑर्डिनेटर</b> से बात करें।</p>
                        <p><b>ध्यान दें: </b>अगर आपने स्कूल के ज़रिए रजिस्ट्रेशन किया है, तो नया अकाउंट न बनाएं। अलग से पैसे लगेंगे और आपको स्पेशल डिस्काउंट नहीं मिलेगा।</p>
                    </div>}
                    footer={<>
                        <SecondaryButton onClick={goToLogin}>Login</SecondaryButton>
                        <PrimaryButton onClick={handleClose}>Skip & continue</PrimaryButton>
                    </>}
                />
            }
            <div className={classes.logoImage}>
                <img src={logoImage} className={classes.bulbImage} alt="Logo" />
                <img src={logoWithTagline} className={classes.tagline} alt="Tag line" />
            </div>
            <div className={classes.mainBox}>
                {!userInfo && <InfoForm isEmailValid={isEmailValid}
                    goToLogin={goToLogin}
                    onSubmit={e => {
                        createUser(e).then(d => {
                            if (d.success) {
                                setUserInfo(e);
                            } else if (d.errorCode === "already_exist") {
                                toast.error("Email or phone number is already exists")
                            }
                        });
                    }}
                    config={infoConfig}
                />}
                {userInfo && <OTPForm
                    onCreate={e =>
                        otpVerification({
                            ...e,
                            ...userInfo
                        }, history, postSignUpProcess).then(e => setOTPError(''))
                            .catch(err => setOTPError(err?.msg || 'Unknown'))
                    }
                    resendOTP={e => resendCode(userInfo)}
                    onBack={e => setUserInfo(null)}
                    errMsg={otpError}
                />}
            </div>
        </div>
    )
}




function SignUp(props) {
    const history = useHistory();
    const dispatch = useDispatch();


    function postSignUpProcess() {
        history.push({
            pathname: Consts.BASE_URL || '/',
            state: {
                from: history.location.pathname
            }
        });
    }

    function goToLogin() {
        history.push({
            pathname: `${Consts.BASE_URL}/sign-in`,
            state: { from: history.location.pathname },
        });
    }

    useEffect(() => {
        if (Utils.isUserLoggedIn()) {
            logout(history, dispatch);
        }
    }, []);

    return (
        <div className={classes.mainContainer}>
            <div className={classes.rightMainBox}>
                <img src={signUpbackgroundImage} className={classes.backgroundImage} />
                <img src={signUpBackgroundStars} className={classes.backgroundStars} />
            </div>
            <SignupForm postSignUpProcess={postSignUpProcess} goToLogin={goToLogin} />
        </div>
    );
}

export default SignUp;