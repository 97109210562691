import classes from "./Invoice.module.css";
import facebookIcon from "../../../../assets/social/facebook.svg";
import youtubeIcon from "../../../../assets/social/youtube.svg";
import instaIcon from "../../../../assets/social/instagram.svg";
import twitterIcon from "../../../../assets/social/twitter.svg";
import pintrestIcon from "../../../../assets/social/pintrest.svg";
import linkedInIcon from "../../../../assets/social/linkedin-round-svgrepo-com.svg";
import whatsapp from "../../../../assets/social/whatsapp.svg";
import { Link } from "react-router-dom";

const socialLinks = [
  {
    href: "https://www.facebook.com/bookalooza",
    src: facebookIcon,
    alt: "Facebook",
  },
  {
    href: "https://www.instagram.com/bookalooza/",
    src: instaIcon,
    alt: "Instagram",
  },
  {
    href: "https://www.youtube.com/@Bookalooza?sub_confirmation=1",
    src: youtubeIcon,
    alt: "YouTube",
  },
  {
    href: "https://www.linkedin.com/company/bookalooza/",
    src: linkedInIcon,
    alt: "LinkedIn",
  },
  { href: "https://twitter.com/bookalooza", src: twitterIcon, alt: "Twitter" },
  {
    href: "https://whatsapp.com/channel/0029Vak6zGaAe5VwdHcBmx0a",
    src: whatsapp,
    alt: "Whatsapp",
  },
  {
    href: "https://in.pinterest.com/Bookaloozacreatebooks",
    src: pintrestIcon,
    alt: "Pinterest",
  },
];

function InvoiceFooter() {
  return (
    <div className={classes.invoiceFooter}>
      <div className={classes.socialLinks}>
        {socialLinks.map((link, index) => (
          <a
            href={link.href}
            key={index}
            target="_blank"
            className={classes.socialIcon}
          >
            <img src={link.src} alt={link.alt} />
          </a>
        ))}
      </div>
      <div className={classes.contactInfo}>
        <p>Orange Vtech Pvt Ltd, 9 Daryaganj, New Delhi-110002 (India)</p>
        <p>
          <a className={classes.phone} href="tel:+918799721408">
            +91 8799721408
          </a>
        </p>
        <p>
          <a href="mailto:info@bookalooza.com">info@bookalooza.com</a> -
          <a href="www.bookalooza.com">www.bookalooza.com</a>
        </p>
      </div>
    </div>
  );
}

export default InvoiceFooter;
