import React from "react";
import GoogleMapReact from "google-map-react";

const apiKey = "AIzaSyBUin7JCbFVYJzsn8sFT3LLMLmq-KWNZtw";

const Marker = ({ text }) => {
  return (
    <div>
      <img alt="" src="https://maps.google.com/mapfiles/ms/icons/red-dot.png" />
      {text}
    </div>
  );
};

function Map(props) {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: apiKey }}
      defaultCenter={{
        lat: props.lat,
        lng: props.lng,
      }}
      defaultZoom={props.zoom}
    >
      <Marker lat={props.lat} lng={props.lng} text={props.text} />
    </GoogleMapReact>
  );
}

export default Map;
