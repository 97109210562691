import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import seo from "../../seo.json";
import { useSelector } from "react-redux";

function HelmetProvider() {
  const location = useLocation();
  const currentPath = location.pathname.slice(1);
  const product = useSelector((state) => state.books.publishedBook || {});
  const books = useSelector((state) => state.books.storeBooks);
  const [seoData, setSeoData] = useState({
    title: "",
    description: "",
    keywords: "",
    schema: "",
  });

  // Extract the SEO data based on the current path, falling back to `seo.base`
  useEffect(() => {
    if (currentPath.includes("details")) {
      if (product) {
        setSeoData({
          title: `${product.title} | ${product.author} | Bookalooza`,
          description:
            product.description ||
            "Bookalooza allows young children to write, publish and earn a 10% royalty through selling their books. We provide in-built designs and AI features to help you write your dream book.",
          keywords: `${product.title}, student published books, young author stories, Bookalooza books, buy student books`,
          schema: {
            "@context": "https://schema.org/",
            "@type": "Product",
            name: product.title,
            image: `${window.origin}${product.frontThumbURL}?${product.updateDate}`,
            description: product.description,
            sku: product.id,
            brand: {
              "@type": "Brand",
              name: "Bookalooza",
            },
            offers: {
              "@type": "Offer",
              url: window.location.href,
              priceCurrency: "INR",
              price: product.regularPrice,
              priceValidUntil: "2024-12-31",
              itemCondition: "https://schema.org/NewCondition",
              availability: "https://schema.org/InStock",
            },
          },
        });
      }
    } else if (currentPath.includes("store")) {
      setSeoData({
        ...seo[currentPath],
        schema: {
          "@context": "https://schema.org",
          "@type": "ItemList",
          name: "Filtered Bookalooza Store",
          url: `https://www.bookalooza.com/store`,
          description:
            "Browse through a collection of books written and published by students on Bookalooza. Support young authors by exploring their works and purchasing their books.",
          itemListOrder: "Descending",
          itemListElement: books.map((e) => ({
            "@type": "Book",
            name: e.title,
            author: e.author,
          })),
        },
      });
    } else {
      setSeoData(seo[currentPath] || seo.base);
    }
  }, [
    currentPath,
    product,
    books,
  ]);

  return (
    <Helmet>
      <title>{seoData.title}</title>
      <meta name="description" content={seoData.description} />
      <script type="application/ld+json">{JSON.stringify(seoData.schema)}</script>
      {seoData.keywords && <meta name="keywords" content={seoData.keywords} />}
    </Helmet>
  );
}

export default HelmetProvider;
