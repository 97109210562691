import React from 'react';
import classes from './BuddingCard.module.css';
import { useState } from 'react';
import LandingPagePopUp from '../LandingPagePopUp/LandingPagePopUp';
import Utils from '../../Utils';
import LinkButton from '../Buttons/LinkButton';

function BuddingCard({ image, title, content, onClick, blogCard }) {


  return (
    <>
      <div className={classes.main} onClick={onClick}>
        <div className={Utils.getClasses(classes.buddingImageWrapper, blogCard && classes.blogImageWrapper)}>
          <img src={image} alt="Budding Image" />
        </div>
        <div className={Utils.getClasses(classes.buddingContentWrapper, blogCard && classes.blogTitleWrapper)}>
          <h1 className={Utils.getClasses(classes.buddingTitle, blogCard && classes.blogTitle)}>{title}</h1>
          {!blogCard && <p className={classes.buddingPara}>{content || "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry."}</p>}
        </div>
        {blogCard && <div className={classes.readMoreContainer}>
          <LinkButton className={classes.readMore}>Read More ...</LinkButton>
        </div>}
      </div>

    </>
  )
}

export default BuddingCard