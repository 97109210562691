import React from "react";
import Utils from "../../../../Utils";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import classes from "./UserDashboardHeader.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useMediaQuery, useTheme } from "@material-ui/core";

function UserDashboardHeader({
  title,
  description,
  disabled,
  buttonText = "Create your book now",
  userDisabled,
  disabledButton,
  helloUserText,
  onButtonClick
}) {
  const history = useHistory();
  const user = Utils.getCurrentUser();
  const theme = useTheme();

  const createBookHandler = () => {
    Utils.createBook(history);
  };
  return (
    <div className={classes.headingWrapper}>
      <div className={classes.container}>
        <div className={classes.headingAndButtonWrapper}>
          <div className={classes.headingAndDescriptionWrapper}>
            <h1 className={classes.heading}>{title}</h1>
            <p className={classes.headingTitle}>{description}</p>
          </div>
          <div className={disabled ? classes.disabled : ""}>
            <PrimaryButton
              onClick={onButtonClick ? onButtonClick : createBookHandler}
              className={classes.actionBtn}
              disabled={disabledButton}
            >
              {buttonText}
            </PrimaryButton>
          </div>
        </div>
        <div
          className={userDisabled ? classes.disabled : classes.helloUserWrapper}
        >
          <p>
            Hello, {user.fullName} ! {helloUserText}
          </p>
        </div>
      </div>
    </div>
  );
}

export default UserDashboardHeader;
