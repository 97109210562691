import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ModalDialog from "../Dialog/Dialog";
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import { addNewCartItem, getAppliedCouponCode, removeAllCartItems } from '../../modules/AddCartScreen/AddCartSlice';
import CheckoutForm from "../PaymentForms/CheckoutForm";
import { createOrder, createProofReadingOrder, schoolRecharge, updateOrderStatus, updateProofreadingStatus, updateRechargeStatus } from '../PaymentForms/PaymentAction';
import logoIcon from "../../assets/bookalooza-logo.png";
import useRazorpay from 'react-razorpay';
import InfoForm from './InfoForm';
import { fetchProfile } from '../../modules/Users/UsersActions';
import { createContext } from 'react';
import CheckBoxPrimary from '../CheckBox/CheckBoxPrimary';
import classes from "./Payment.module.css"
import PrimaryButton from '../Buttons/PrimaryButton';
import Consts from '../../Consts';
import { useHistory } from "react-router";
import Modal, { ModalFooter } from '../Modal/Modal';
import { publishBookById } from '../../modules/Order/ProductOrderActions';

const PaymentContext = createContext();
const PaymentInnerContainer = PaymentContext.Provider;

export function PaymentContainer({ children }) {
    const [Razorpay, isLoaded] = useRazorpay();
    const [razor, setRazor] = useState({});
    useEffect(() => {
        if (isLoaded) {
            setRazor({ value: Razorpay });
        }
    }, [Razorpay, isLoaded]);
    return <PaymentInnerContainer value={razor}>
        {children}
    </PaymentInnerContainer>
}

function useRazor() {
    return useContext(PaymentContext).value;
}

function usePayment(config, userData, updateOrderStatus, onClose, bookId) {
    const [method, setMethod] = useState({
        fn: () => { console.log("Payment Gateway not init") }
    });
    const dispatch = useDispatch();
    const Razorpay = useRazor();

    function setFunction(fn) {
        setMethod({ fn });
    }

    useEffect(() => {
        if (config?.gateway === 'razorpay') {
            setFunction(function (order, setOrderStatus) {
                return new Promise((accept, reject) => {
                    if (!Razorpay) {
                        reject("Razor not found");
                        return;
                    }
                    const options = {
                        key: config.key,
                        order_id: order.gatewayOrderId,
                        description: "Book purchase",
                        image: logoIcon,
                        handler: async function (response) {
                            const resp1 = await updateOrderStatus({
                                ...response,
                                id: order.id,
                                gateway_order_id: response.razorpay_order_id,
                                gateway_payment_id: response.razorpay_payment_id,
                                gateway_payment_status: 'success',
                            }, bookId);
                            if (resp1.status) {
                                setOrderStatus(1);
                                dispatch(removeAllCartItems());
                                fetchProfile(dispatch);
                            }
                            accept(resp1);
                        },
                        modal: {
                            ondismiss: function () {
                                updateOrderStatus({
                                    id: order.id,
                                    razorpay_order_id: order.gatewayOrderId,
                                    gateway_order_id: order.gatewayOrderId,
                                    gateway_payment_id: "",
                                    razorpay_payment_id: "",
                                    razorpay_signature: "",
                                    gateway_payment_status: 'failed',
                                }, bookId).then(accept);
                                onClose?.();
                            }
                        },
                        prefill: {
                            name: userData.fullName,
                            email: userData.userId,
                        },
                        theme: {
                            color: "#6c12ab"
                        },
                        notes: {},
                    };

                    const rzp1 = new Razorpay(options);
                    rzp1.on("payment.failed", async function (response) {
                        const resp1 = await updateOrderStatus({
                            gateway_order_id: order.razorpayOrderId,
                            gateway_payment_id: response.error.metadata.payment_id,
                            razorpay_signature: "",
                            gateway_payment_status: 'failed',
                        }, bookId);
                        setOrderStatus(2);
                        accept(resp1);
                    });
                    rzp1.open();
                })
            });
        } else if (config?.gateway === 'instamojo') {
            setFunction(function (order) {
                if (order.gatewayPaymentLink) {
                    window.location.href = order.gatewayPaymentLink;
                }
            });
        }
    }, [config, userData, Razorpay, dispatch, bookId, onClose, updateOrderStatus])
    return method.fn;
}


export default function Payment({ cartItems, setShowCheckoutForm, userData, config }) {
    const [orderStatus, setOrderStatus] = useState(0);
    const dispatch = useDispatch();
    const doPayment = usePayment(config, userData, updateOrderStatus);

    async function handlePayment(state, customerName, customerPhone, city, address, pincode, addressLine2, landmark, alternatePhone) {
        const { orderData, draftBooks } = cartItems.reduce(
            (acc, item) => {
                acc.orderData.push({
                    bookId: item.id,
                    quantity: item.count,
                    isProofReadingService: item.isProofReadingService,
                    isListingService: item.isListing
                });
                if (item.status === "DRAFT" && item.authorId === userData.id) {
                    acc.draftBooks.push(item);
                }
                return acc;
            },
            { orderData: [], draftBooks: [] }
        );


        setShowCheckoutForm(false);
        const couponCode = getAppliedCouponCode().promoCode;
        const params = { "products": orderData, state, customerName, customerPhone, address, pincode, city, addressLine2, landmark, alternatePhone, couponCode };

        try {
            // Create the order
            const order = await createOrder(params);
            // Handle order status
            if (order.status === 'success') {
                setOrderStatus(1);
                dispatch(removeAllCartItems());
            } else {
                await doPayment(order, async (e) => {
                    setOrderStatus(e);
                    if (e === 1) await Promise.all(draftBooks.map(book => publishBookById(book.id)));
                });
            }

            // Fetch profile if credits were used
            if (order.usedCredits) {
                fetchProfile(dispatch);
            }


        } catch (error) {
            console.error("Payment handling error:", error);
        }
    }

    const Form = userData.isSchoolShipped ? InfoForm : CheckoutForm;
    return <>
        <ModalDialog content={
            <Form setShowCheckoutForm={setShowCheckoutForm} handlePayment={handlePayment} userData={userData} />
        } />
        {orderStatus === 1 &&
            <ModalDialog title="Order Success" content={
                <div>Your order has been placed successfully.</div>
            } footer={
                <div>
                    <SecondaryButton onClick={() => setOrderStatus(0)}>Ok</SecondaryButton>
                </div>
            } />
        }
        {orderStatus === 2 &&
            <ModalDialog title="Payment Failed" content={
                <div>Your order has been failed or declined.</div>
            } footer={
                <div>
                    <SecondaryButton onClick={() => setOrderStatus(0)}>Ok</SecondaryButton>
                </div>
            } />
        }
    </>
}

export function AddWalletBalance({ config, userData, onClose }) {
    const doPayment = usePayment(config, userData, updateRechargeStatus, onClose);
    const [orderStatus, setOrderStatus] = useState(0);


    useEffect(() => {
        if (config && userData && userData.gatewayPaymentStatus !== "success") {
            schoolRecharge().then(e => {
                doPayment(e, setOrderStatus);
            })
        }
    }, [config, userData, doPayment])

    const handleOk = () => {
        setOrderStatus(0);
        onClose?.();
    }

    return <>
        {orderStatus === 1 &&
            <ModalDialog title="Success" content={
                <div>Good news! Your amount of Rs.{userData.credits} is loaded in the wallet. And hey, when it's time to publish, this amount will be adjusted at checkout. So, no need to fret! Create your Book Now!</div>
            } footer={
                <div>
                    <SecondaryButton onClick={handleOk}>Ok</SecondaryButton>
                </div>
            } />
        }
        {orderStatus === 2 &&
            <ModalDialog title="Payment Failed" content={
                <div>Your order has been failed or declined.</div>
            } footer={
                <div>
                    <SecondaryButton onClick={handleOk}>Ok</SecondaryButton>
                </div>
            } />
        }
    </>
}

export function ProofreadingPayment({ setShow, book }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(true);
    const [isListing, setIsListing] = useState(false);
    const [isProofReadingService, setIsProofReadingService] = useState(true);

    const handleBuyNow = async () => {
        dispatch(addNewCartItem({ ...book, count: 1, isProofReadingService, isListing }));
        setShowModal(false);
        history.push(`${Consts.BASE_URL}/cart`);
    };

    const pages = book?.totalPages - 4 || 25;

    return (
        <>
            {
                showModal && <ModalDialog
                    title="Let our Experts handle it!"
                    content={
                        <>
                            <span>
                                Add a professional touch to your writing with our <b>grammar</b> and{" "}
                                <b>proofreading</b> services.
                            </span>
                            <br />
                            <div className={classes.proofreadingContainer}>
                                <div className={classes.proofreadingGrid}>
                                    <div className={classes.subGrid}>
                                        <div>
                                            {pages <= 64
                                                ? "24 - 64 pages"
                                                : pages > 64 && pages <= 120
                                                    ? "64 - 120 pages"
                                                    : "120 and above"}
                                        </div>
                                        <div>
                                            Rs.{" "}
                                            {pages <= 64
                                                ? "100"
                                                : pages > 64 && pages <= 120
                                                    ? "200"
                                                    : "350"}
                                        </div>
                                        <div>
                                            <CheckBoxPrimary
                                                defaultChecked={isProofReadingService}
                                                onChange={() => setIsProofReadingService((p) => !p)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.proofreadingContainer}>
                                Would you like to Display your book on <b>Flipkart & Amazon</b> for Sale?
                            </div>
                            <div className={classes.proofreadingContainer}>
                                <div className={classes.proofreadingGrid}>
                                    <div className={classes.subGrid}>
                                        <div>
                                            Listing
                                        </div>
                                        <div>
                                            Rs. 100
                                        </div>
                                        <div>
                                            <CheckBoxPrimary
                                                defaultChecked={isListing}
                                                onChange={() => setIsListing(p => !p)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    footer={
                        <>
                            <div>
                                <SecondaryButton
                                    onClick={() => {
                                        dispatch(addNewCartItem({ ...book, count: 1, isProofReadingService: false, isListing: false }));
                                        setShow(false);
                                        setShowModal(false);
                                        history.push(`${Consts.BASE_URL}/cart`);
                                    }}
                                >Skip & continue</SecondaryButton>
                            </div>
                            <div>
                                <PrimaryButton onClick={handleBuyNow}>Buy Now</PrimaryButton>
                            </div>
                        </>
                    }
                />
            }
        </>
    );
}