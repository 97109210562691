import React from "react";
import classes from "./knowAboutDiscount.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Divider from "../../components/Divider/Divider";
import Utils from "../../Utils";
import discountIcon from "../../assets/discount-icon.svg";

function KnowAboutDiscount() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={classes.main}>
      <div className={classes.headingContainer}>
        <div className={classes.headingWrapper}>
          <h1 className={classes.heading}>Know About Discount</h1>
          <img src={discountIcon} alt="Know About Discount" />
        </div>
      </div>
      <Divider style={{ margin: "1rem 0" }} />
      <div className={classes.container}>
        <div className={classes.authorDiscountWrapper}>
          <p className={classes.authorDiscountHeading}>Author Discount</p>
          <p className={classes.authorDiscount}>10% On MRP</p>
        </div>
        <div className={classes.quantityDiscountTableWrapper}>
          <label
            htmlFor="quantityDiscountTable"
            className={classes.quantityDiscountTableLabel}
          >
            Quantity Discount
          </label>
          <div id="quantityDiscountTable" className={classes.quantityDiscountTable}>
            <div className={classes.quantityDiscountTableHeading}>
              <div className={classes.tableHeadingCell}>No. Of Books</div>
              <div className={classes.tableHeadingCell}>Discount</div>
            </div>
            <div className={classes.tableRowWrapper}>
              <div className={classes.tableBodyCell}>01 — 09</div>
              <div className={classes.tableCell}>No Discount</div>
            </div>
            <div className={classes.tableRowWrapper}>
              <div className={classes.tableBodyCell}>10 — 19</div>
              <div className={classes.tableCell}>5% Discount</div>
            </div>
            <div className={classes.tableRowWrapper}>
              <div className={classes.tableBodyCell}>20 — 49</div>
              <div className={classes.tableCell}>10% Discount</div>
            </div>
            <span className={classes.mostRecommendedLabel}>
              Most Recommended
            </span>
            <div className={Utils.getClasses(classes.tableRowWrapper, classes.mostRecommended)}>

              <div
                className={Utils.getClasses(
                  classes.tableBodyCell,
                  classes.mostRecommendedCell
                )}
              >
                50 — 99
              </div>
              <div className={classes.mostRecommendedCell}>15% Discount</div>
            </div>
            <div className={classes.tableRowWrapper}>
              <div className={classes.tableBodyCell}>100+</div>
              <div className={classes.tableCell}>20% Discount</div>
            </div>
          </div>

        </div>
        {/* <Divider /> */}
        {/* <div className={classes.disclaimer}>
          If 50 or more copies of a book are sold, ISBN number will be alloted
          to the book and it will be available for sale on major platforms like{" "}
          <span className={classes.boldText}>
            Amazon, Flipkart, Meesho
          </span>
          {" "}etc.
        </div> */}
      </div>
    </div>
  );
}

export default KnowAboutDiscount;
