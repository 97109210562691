import React from "react";
import classes from "../HomeScreen.module.css";
import editorChoiceBanner from "../../../assets/Bookalooza-Editor-Choice-Banner.jpg";
import { Link } from "react-router-dom";

const books = [
  {
    to: "/details/the-laureate-of-moonlit-dreams",
    frontThumb: "https://www.bookalooza.com/designer/storage/books/66c981272c08a45b5afe55f1/mogjpman.png?1733226905557"
  },
  {
    to: "/details/the-rumour",
    frontThumb: "https://www.bookalooza.com/designer/storage/books/6705054608b628047553e6ff/79hcxxzd.png?1731049466306"
  },
  {
    to: "/details/shadowed-hallways-the-hidden-world-of-schoolat-night",
    frontThumb: "https://www.bookalooza.com/designer/storage/books/665ac36c243a4c765e3f0c08/8nx0lrsm.png?1733140964703"
  },
  {
    to: "/details/shadow-nexus-the-cursed-power",
    frontThumb: "https://www.bookalooza.com/designer/storage/books/66965cabcf103b21e83c20d5/ro4310d7.png?1728285450040"
  },
  {
    to: "/details/pursuit-of-shadows-chasing-the-devil",
    frontThumb: "https://www.bookalooza.com/designer/storage/books/66608583c550571afbd49643/kmd472g6.png?1733139815781"
  },
]

function EditorsChoice() {
  return (
    <div className={classes.editorChoiceMain}>
      <h3 className={classes.editorChoiceHeading}>Editor’s Choice: Top Books</h3>
      <div className={classes.imageContainer}>
        <div className={classes.editorsChoiceWrapper}>
          {
            books.map((e) => <Link to={e.to}>
              <img src={e.frontThumb} alt={e.frontThumb} />
            </Link>)
          }
        </div>
      </div>
    </div>
  );
}

export default EditorsChoice;
