import React, { useState, useEffect } from "react";
import { useActiveFabricObject, useFabricCanvasHandler } from "../../FabricCanvas";
import { BiEndSlider, Slider } from "../../../../components/Sliders/Sliders";
import { useDispatch, useSelector } from 'react-redux';
import ToolPanelButton from "../../../../components/Buttons/ToolPanelButton";
import Select from "../../../../components/Select";
import Container from "../components/Container";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import { useBookContext, useBookContextHandler, usePageContext } from "../../BookContext";
import { booksActions } from "../../../Books/BooksSlice";
import CheckBox from '../../../../components/CheckBox/CheckBox'
import classes from './TextView.module.css'
import { TextColorStrip } from "../ColorView";
import { ActiveTextboxObject, addTextboxObject } from "../../objectUtils";
import Input from "../../../../components/Input/Input";
import Utils from "../../../../Utils";
import JustifiedLeft from "../../../../components/Icons/JustifiedLeft";
import JustifiedRight from "../../../../components/Icons/JustifiedRight";
import JustifiedCenter from "../../../../components/Icons/JustifiedCenter";
import toast from "react-hot-toast";

const fabric = window.fabric;
const preFontSize = { min: 4, max: 50 }


function addFallbackFont(fontFamily) {
    return `${fontFamily},Hind`;
}
function removeFallbackFont(fontFamily) {
    return fontFamily?.replace(',Hind', '');
}
function isNullOrUndef(val) {
    return val === null || val === undefined;
}

function Basic() {
    const fontData = useSelector(s => s.fonts);
    const user = useSelector(s => s.user.user);
    const defaultColor = useSelector(s => s.foregroundColors.defaultColor);
    const fonts = fontData.libraries.map(lib =>
        lib.fonts.map((f, idx) => ({
            label: f.familyName,
            value: f.familyName,
            style: {
                fontFamily: f.familyName
            },
            font: f
        }))
    );

    const [selectedValue, setSelectedValue] = useState(0);
    const [activeObject, update] = useActiveFabricObject(ActiveTextboxObject);
    const fontOpts = fonts[selectedValue] || [];
    const [fontSize, setFontSize] = useState(9);

    const page = usePageContext();

    const dispatch = useDispatch();
    const bookCtx = useBookContext();
    const [canvas] = useFabricCanvasHandler({
        'object:modified': function ({ target }) {
            if (target.id === 'title') {
                let title = target.text;
                bookCtx.setTitle(title);
                dispatch(booksActions.updateBookTitle(title));
            } else if (target.id === 'author') {
                bookCtx.setAuthor(target.text);
            } else if (target.id === 'aboutAuthor') {
                bookCtx.setAboutAuthor(target.text);
            }
            
            if (target.text && !target.hasShownToast) {
                target.hasShownToast = true;
                toast(
                    <div className="toast-message">
                        <h4 className="toast-title">To make your book look great:</h4>
                        <p><b>Headings</b> should be <b>12 pt.</b></p>
                        <p><b>Text</b> should be <b>8 pt.</b></p>
                    </div>
                );
            }
        },
        'text:selection:changed': function () {
            update();
            if (activeObject) {
                setFontSize(activeObject.get('fontSize'));
            }
        },
        'canvas:x-loaded': function () {
            delete window.Ext.canvasController.isTextDirty;
        }
    }, [selectedValue, bookCtx, activeObject]);

    useBookContextHandler({
        'page:mounted': function () {
            const contentsLayer = canvas.getLayerById('contents');
            if (!contentsLayer) {
                return
            }
            for (let fabObj of contentsLayer._objects) {
                if (fabObj instanceof fabric.Textbox) {
                    if (!fabObj.text) {
                        canvas.setActiveObject(fabObj);
                        fabObj.enterEditing();
                        break;
                    }
                }
            }
        }
    }, [canvas]);

    const fontFamily = removeFallbackFont(activeObject.get('fontFamily'));
    const fontWeight = activeObject.get('fontWeight');
    const fontStyle = activeObject.get('fontStyle');
    const alignment = activeObject.get('textAlign');
    const tracking = activeObject.get('charSpacing');
    const lineHeight = activeObject.get('lineHeight');
    const underline = activeObject.get('underline');
    const enableTransliteration = activeObject.get('enableTransliteration');
    useEffect(() => {
        if (activeObject) {
            setFontSize(activeObject.get('fontSize'));
        }
    }, [activeObject?._activeObjects[0]]);

    function setFont(font) {
        fabric.util.fontLoader.loadFonts([
            {
                fontFamily, fontStyle, fontWeight,
                ...font
            }
        ]).then(e => {
            activeObject.setProps(font);
            canvas.renderAll();
            update();
            activeObject.fireModified();
        })
    }

    useEffect(() => {
        if (canvas && activeObject) {
            const handleKeyDown = (event) => {
                const modifier = event.ctrlKey || event.metaKey;
                if (modifier && event.key === "b") {
                    setFont({
                        fontWeight: fontWeight === "bold" ? "normal" : "bold",
                    });
                } else if (modifier && event.key === "i") {
                    setFont({
                        fontStyle: fontStyle === "italic" ? "normal" : "italic",
                    });
                } else if (modifier && event.key === "u") {
                    event.preventDefault();
                    activeObject.set("underline", !underline);
                    canvas.renderAll();
                    update();
                    activeObject.fireModified();
                } else if (modifier && event.key === "l") {
                    event.preventDefault();
                }
            };

            window.addEventListener("keydown", handleKeyDown);
            return () => {
                window.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [canvas, fontWeight, fontStyle, underline, activeObject, setFont, update]);

    return <>
        <div className={classes.checkBoxWrapper}>
            <label className="editor-text" style={{ fontSize: "1rem" }}>हिन्दी :</label>
            <CheckBox
                value={enableTransliteration || false}
                onChange={e => {
                    const checked = e.target.checked;
                    activeObject.set('enableTransliteration', checked);
                    fabric.TextArea.prototype.enableTransliteration = checked;
                    canvas.drawControls(canvas.contextContainer);
                    update();
                }}
            />
        </div>
        <div>
            <label className="editor-text" style={{ fontSize: "1rem" }}>Font</label>
            <div style={{ color: 'black', marginTop: '0.5rem' }}>
                <Select
                    closeMenuOnSelect={false}
                    options={fontOpts}
                    onChange={e => {
                        setFont({
                            fontFamily: addFallbackFont(e.value)
                        });
                    }}
                    value={fontOpts.find(i => i.value === fontFamily) || null}
                    isOptionDisabled={(option) => option.disabled}
                    placeholder={'Select Font...'}
                    clearable={true}
                    styles={{
                        control: e => ({ fontFamily }),
                        option: (base, state) => state.data?.style
                    }}
                />
            </div>
        </div>
        <div style={{ marginTop: '0.5rem' }}>
            <label className="editor-text" style={{ fontSize: "1rem" }}>Font Size</label>
            <div style={{ color: 'black', marginTop: '0.5rem' }}>
                <Input
                    style={{ border: "1px solid", borderRadius: 0 }}
                    type="number"
                    value={fontSize}
                    min={preFontSize.min}
                    max={preFontSize.max}
                    onChange={e => {
                        let inpVal = e.target.value;
                        let val = Number(inpVal);
                        if (isNaN(val) || val < preFontSize.min) {
                            val = preFontSize.min;
                        } else if (val > preFontSize.max) {
                            val = preFontSize.max
                        }
                        if (inpVal !== '') {
                            inpVal = Number(inpVal);
                            if (inpVal < 1) {
                                inpVal = 1;
                            } else if (inpVal > preFontSize.max) {
                                inpVal = preFontSize.max
                            }
                        }
                        setFontSize(inpVal);
                        activeObject.set('fontSize', val);
                        canvas.renderAll();
                        update();
                    }}
                />
            </div>
        </div>
        <div style={{ marginTop: '0.5rem' }}>
            <label className="editor-text" style={{ fontSize: "1rem" }}>Style</label>
            <div style={{ marginTop: '0.5rem', display: 'flex' }}>
                <ToolPanelButton
                    pressed={fontWeight === 'bold'}
                    disabled={isNullOrUndef(fontWeight)}
                    className="fa-bold text-view-btn-style1"
                    onClick={e => {
                        setFont({
                            fontWeight: fontWeight === 'bold' ? "normal" : "bold"
                        });
                    }}
                    title="Bold (CTRL + B)"
                />
                <ToolPanelButton
                    pressed={fontStyle === 'italic'}
                    style={{ marginLeft: "0.5rem" }}
                    disabled={isNullOrUndef(fontStyle)}
                    className="fa-italic text-view-btn-style1"
                    onClick={e => {
                        setFont({
                            fontStyle: fontStyle === 'italic' ? "normal" : "italic"
                        });
                    }}
                    title="Italics (CTRL + I)"
                />
                <ToolPanelButton
                    pressed={underline}
                    disabled={isNullOrUndef(underline)}
                    style={{ marginLeft: "0.5rem" }}
                    className="fa-underline text-view-btn-style1"
                    onClick={e => {
                        activeObject.set('underline', !underline);
                        canvas.renderAll();
                        update();
                        activeObject.fireModified();
                    }}
                    title="Underline (CTRL + U)"
                />
            </div>
        </div>
        <div style={{ marginTop: '0.5rem' }}>
            <label className="editor-text" style={{ fontSize: "1rem" }}>Alignment</label>
            <div style={{ marginTop: '0.5rem', display: 'flex' }}>
                <ToolPanelButton
                    disabled={isNullOrUndef(alignment)}
                    pressed={alignment === 'left'}
                    className="fa-align-left text-view-btn-style1"
                    onClick={e => {
                        activeObject.set('textAlign', 'left');
                        canvas.renderAll();
                        update();
                        activeObject.fireModified();
                    }}
                    title="Left"
                />
                <ToolPanelButton
                    disabled={isNullOrUndef(alignment)}
                    pressed={alignment === 'center'}
                    className="fa-align-center text-view-btn-style1"
                    onClick={e => {
                        activeObject.set('textAlign', 'center');
                        canvas.renderAll();
                        update();
                        activeObject.fireModified();
                    }}
                    style={{ marginLeft: "0.5rem" }}
                    title="Center"
                />
                <ToolPanelButton
                    disabled={isNullOrUndef(alignment)}
                    pressed={alignment === 'right'}
                    className="fa-align-right text-view-btn-style1"
                    onClick={e => {
                        activeObject.set('textAlign', 'right');
                        canvas.renderAll();
                        update();
                        activeObject.fireModified();
                    }}
                    style={{ marginLeft: "0.5rem" }}
                    title="Right"
                />
                <ToolPanelButton
                    disabled={isNullOrUndef(alignment)}
                    pressed={alignment === 'justify'}
                    className="fa-align-justify text-view-btn-style1"
                    onClick={e => {
                        activeObject.set('textAlign', 'justify');
                        canvas.renderAll();
                        update();
                        activeObject.fireModified();
                    }}
                    style={{ marginLeft: "0.5rem" }}
                    title="Justified"
                />
                <ToolPanelButton disabled={isNullOrUndef(alignment)}
                    pressed={alignment === 'justify-left'}
                    className="text-view-btn-style1"
                    onClick={e => {
                        activeObject.set('textAlign', 'justify-left');
                        canvas.renderAll();
                        update();
                        activeObject.fireModified();
                    }}
                    style={{ marginLeft: "0.5rem" }}
                    title="Justified all lines left"
                ><JustifiedLeft /></ToolPanelButton>
                <ToolPanelButton disabled={isNullOrUndef(alignment)}
                    pressed={alignment === 'justify-center'}
                    className="text-view-btn-style1"
                    onClick={e => {
                        activeObject.set('textAlign', 'justify-center');
                        canvas.renderAll();
                        update();
                        activeObject.fireModified();
                    }}
                    style={{ marginLeft: "0.5rem" }}
                    title="Justified all lines center"
                ><JustifiedCenter /></ToolPanelButton>
                <ToolPanelButton disabled={isNullOrUndef(alignment)}
                    pressed={alignment === 'justify-right'}
                    className="text-view-btn-style1"
                    onClick={e => {
                        activeObject.set('textAlign', 'justify-right');
                        canvas.renderAll();
                        update();
                        activeObject.fireModified();
                    }}
                    style={{ marginLeft: "0.5rem" }}
                    title="Justified all lines right"
                ><JustifiedRight /></ToolPanelButton>
            </div>
        </div>
        {
            (user && (user?.role === "admin" || user?.role === "editor")) && <>
                <div style={{ marginTop: '0.5rem' }}>
                    <label className="editor-text" style={{ fontSize: "1rem" }}>Letter Spacing</label>
                    <div className={isNullOrUndef(tracking) ? 'x-item-disabled' : ''} style={{ marginTop: '0.5rem' }}>
                        <BiEndSlider min={-100}
                            max={200}
                            value={Number(tracking) || 0}
                            style={{ marginBottom: 'var(--label-margin-bottom)' }}
                            onChange={(ev, val) => {
                                activeObject.set('charSpacing', val);
                                canvas.renderAll();
                            }}
                            onChangeCommitted={(ev, val) => {
                                update();
                                activeObject.fireModified();
                            }}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '0.5rem' }}>
                    <label className="editor-text" style={{ fontSize: "1rem" }}>Line Spacing</label>
                    <div className={isNullOrUndef(lineHeight) ? 'x-item-disabled' : ''} style={{ marginTop: '0.5rem' }}>
                        <Slider min={1}
                            max={4}
                            step={0.1}
                            value={Number(lineHeight) || 1}
                            style={{ marginBottom: 'var(--label-margin-bottom)' }}
                            onChange={(ev, val) => {
                                activeObject.set('lineHeight', val);
                                canvas.renderAll();
                            }}
                            onChangeCommitted={(ev, val) => {
                                update();
                                activeObject.fireModified();
                            }}
                        />
                    </div>
                </div>
            </>
        }
        <div style={{ marginTop: '0.5rem' }}>
            <label className="editor-text" style={{ fontSize: "1rem" }}>Colors</label>
            <div className={Utils.getClasses(classes.colorStrip, activeObject.isEmpty() && 'x-item-disabled')} >
                <TextColorStrip />
            </div>
        </div>
        <div style={{ height: '7rem', position: (page?.isRegularPage() || page?.isFrontPage()) ? "sticky" : "static", zIndex: 1, backgroundColor: "var(--panel-semi-dark-background)", bottom: "1rem", display: "flex", alignItems: "center" }}>
            {
                (page?.isRegularPage() || page?.isFrontPage()) &&

                <PrimaryButton style={{ alignSelf: "flex-start", marginTop: '1rem', height: "fit-content", padding: ".75rem" }} onClick={e => {
                    let options = {
                        fontFamily: (fontOpts[0]).value + ",Hind,Emoji",
                        fontWeight: fontWeight === 'bold' ? 'bold' : 'normal',
                        fontStyle: fontStyle === 'italic' ? 'italic' : 'normal',
                        fill: defaultColor,
                        allowedLeft: page.isRegularPage() ? [[15, 142], [170, 297]] : [[15, 142]]
                    }
                    if (page?.isRegularPage()) {
                        options.left = 170;
                        options.top = 15;
                    }
                    addTextboxObject(canvas, options);
                }}>Add Textbox</PrimaryButton>
            }
        </div>
    </>;
}


export default function TextView({ visible }) {
    return <Container visible={visible} title="Textbox" style={{ height: 'fit-content' }}>
        <Basic />
    </Container>;
}
