import React from "react";
import { useHistory } from "react-router";
import classes from "./SignIn.module.css";
import Consts from "../../Consts";
import SignInForm from "./SignInForm";


function SignIn(props) {
	const history = useHistory();

	function postSignInProcess() {
		localStorage.setItem("isLoggedIn", "true");
		history.push(history.location?.state?.from || Consts.BASE_URL || '/');

	}

	function createHandler() {
		history.push({
			pathname: `${Consts.BASE_URL}/sign-up`,
			state: { ...history.location?.state },
		});
	}

	function forgotPasswordHandler() {
		history.push({
			pathname: `${Consts.BASE_URL}/forgot-password`,
			state: { ...history.location?.state },
		});
	}

	return (
		<div className={classes.mainContainer}>
			<SignInForm
				postSignInProcess={postSignInProcess}
				createHandler={createHandler}
				forgotPasswordHandler={forgotPasswordHandler}
				fetchedUserDetails={props.userDetails || {}}
			/>
		</div>
	);
}

export default SignIn;
