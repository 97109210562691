import React from "react";
import classes from "../HomeScreen.module.css";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory } from "react-router";
import landingPageImage from "../../../assets/homepageBanner.png";
import landingMobilePageImage from "../../../assets/homepageMobileBanner.png";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Utils from "../../../Utils";

function HeroSection() {
  const history = useHistory();
  const theme = useTheme();
  const isMobScreen = !useMediaQuery(theme.breakpoints.up("sm"));

  const createBookHandler = () => {
    Utils.createBook(history);
  };

  return (
    <div className={classes.heroImageContainer}>
      <img
        src={isMobScreen ? landingMobilePageImage : landingPageImage}
        alt="hero"
      />
      <div
        className={
          isMobScreen ? classes.createMobileButton : classes.createButton
        }
      >
        <PrimaryButton onClick={createBookHandler}>
          Get Started Now!
        </PrimaryButton>
      </div>
    </div>
  );
}

export default HeroSection;
