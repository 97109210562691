import React from "react";
import classes from "./Invoice.module.css";
import Utils from "../../../../Utils";

function InvoicePaymentDetails({
  subtotal = 0,
  shipping,
  total,
  proofreading = 0,
  listing = 0
}) {
  return (
    <div className={classes.paymentDetails}>
      <div>
      </div>
      <div>
        <div className={classes.paymentBreakdown}>
          <span>Subtotal:</span>
          <span>{Utils.getRupeeSymbol} {subtotal.toFixed(2)}</span>
        </div>
        <div className={classes.paymentBreakdown}>
          <span>Shipping:</span>
          <span>{Utils.getRupeeSymbol} {shipping.toFixed(2)}</span>
        </div>
        <div className={classes.paymentBreakdown}>
          <span>Proofreading Charges:</span>
          <span>{Utils.getRupeeSymbol} {proofreading.toFixed(2)}</span>
        </div>
        <div className={classes.paymentBreakdown}>
          <span>Amazon & Flipkart Listing Charges:</span>
          <span>{Utils.getRupeeSymbol} {listing.toFixed(2)}</span>
        </div>
        <div className={classes.totalBreakdown}>
          <span>TOTAL:</span>
          <span className={classes.totalAmount}>{Utils.getRupeeSymbol} {total.toFixed(2)}</span>
        </div>{" "}
      </div>
    </div>
  );
}

export default InvoicePaymentDetails;
